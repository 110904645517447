import React, { useEffect, useState } from 'react';

function App() {
  const [pokemonData, setPokemonData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [limit, setLimit] = useState(10); 
  const [shuffle, setShuffle] = useState(false);

  // APIからデータを取得する関数
  useEffect(() => {
    fetch(`https://quiz-poke-data-api.akira9238pg.workers.dev/api/pokedata?limit=${limit}`)
      .then(response => response.json())
      .then(data => {
        setCurrentIndex(0) //インデックスを初期化(バグ回避のため)
        if (shuffle) {
          data = shuffleArray(data); // データをシャッフル
        }
        setPokemonData(data)
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [limit,shuffle]);

  // 次のポケモンを表示する関数
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % pokemonData.length);
  };

  // 配列をシャッフルする関数
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  // シャッフルを切り替える関数
  const toggleShuffle = () => {
    setShuffle(prevShuffle => !prevShuffle); // 現在の状態を反転させる
  };

  function getTypeIconName(e) {
    let t;
    switch (parseInt(e)) {
    case 0:
        t = "cmn_type_normal";
        break;
    case 1:
        t = "cmn_type_fighting";
        break;
    case 2:
        t = "cmn_type_flying";
        break;
    case 3:
        t = "cmn_type_poison";
        break;
    case 4:
        t = "cmn_type_ground";
        break;
    case 5:
        t = "cmn_type_rock";
        break;
    case 6:
        t = "cmn_type_bug";
        break;
    case 7:
        t = "cmn_type_ghost";
        break;
    case 8:
        t = "cmn_type_steel";
        break;
    case 9:
        t = "cmn_type_fire";
        break;
    case 10:
        t = "cmn_type_water";
        break;
    case 11:
        t = "cmn_type_grass";
        break;
    case 12:
        t = "cmn_type_electric";
        break;
    case 13:
        t = "cmn_type_psychic";
        break;
    case 14:
        t = "cmn_type_ice";
        break;
    case 15:
        t = "cmn_type_dragon";
        break;
    case 16:
        t = "cmn_type_dark";
        break;
    case 17:
        t = "cmn_type_fairy";
        break;
    default:
        t = "cmn_type_normal"
    }
    return t += "_icon",
    t
}

  // 現在のポケモンデータを取得
  const currentPokemon = pokemonData[currentIndex];

  // ポケモンのタイプを表示するコンポーネント
  function PokemonTypes({ types }) {
    return (
      <div className="t_pokemonTypeContainer">
        {types.map((type, index) => (
          <div key={index} className={`type${index + 1}Container t_typeColor${type}`}>
            <div className={`icon icon-${getTypeIconName(type)}`}></div>
          </div>
        ))}
      </div>
    );
  }

  function BaseStats({ stats }) {
    // 種族値を配列に変換
    const statEntries = Object.entries(stats);

    // 最後の要素を削除し、変数に格納
    const sum = statEntries.pop();
  
    return (
      <>
        <p>種族値 {sum}</p>
        <div className="base-stats-container">
          {/* 奇数個目のデータ（左のリスト） */}
          <ul className="base-stat-list">
            {statEntries.map(([key, value], index) => 
              index % 2 === 0 ? <li key={key}>{key}: {value}</li> : null
            )}
          </ul>
          {/* 偶数個目のデータ（右のリスト） */}
          <ul className="base-stat-list">
            {statEntries.map(([key, value], index) => 
              index % 2 !== 0 ? <li key={key}>{key}: {value}</li> : null
            )}
          </ul>
        </div>
      </>
    );
  }

  // 選択リストの変更ハンドラ
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };
  
  

  

  return (
    <div className="App centered-container">
      {currentPokemon ? (
        <div className="main_container">
          <div className='poke_info_container'>
            <h2>{currentPokemon.name}</h2>
            <PokemonTypes types={currentPokemon.type} />
            <BaseStats stats={currentPokemon.base_stat} />
          </div>
          <div className="util_container">
            <select onChange={handleLimitChange} value={limit}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
      
            {/* シャッフル切り替えボタン */}
            <button onClick={toggleShuffle}>
              {shuffle ? 'ランキング順' : 'シャッフル'}
            </button>
            <button className="next_buton" onClick={handleNext}>Next</button>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default App;
